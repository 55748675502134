<template>
	<div>
		<router-view></router-view>
		<div style="height: 5rem;"></div>
		<TabbarHtml/>
	</div>
</template>

<script>
import TabbarHtml from '@/components/tab.vue'

export default {
	components: {
		TabbarHtml,
	},
}
</script>
