<template>
	<div class="tabbar">
		<van-tabbar v-model="tabbarTempValue" @change="onChange">
			<van-tabbar-item icon="cart-o">提货</van-tabbar-item>
			<van-tabbar-item icon="search">查询</van-tabbar-item>
		</van-tabbar>
	</div>
</template>

<script>
import Vue from 'vue'
import { Tabbar, TabbarItem } from 'vant'
Vue.use(Tabbar).use(TabbarItem)

export default {
	data() {
		return {
			tabbarTempValue: 0,
		}
	},

	//监听路由变化
	watch: {
		$route(to) {
			this.tabbarTempValue = to.path=='/buy'?0:1;
		},
	},

	methods: {
		onChange(index) {
			const routerArray = ['/buy', '/find']
			this.$router.push(routerArray[index])
		},
	},
}
</script>
